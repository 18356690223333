<template>
     <div class="land-checking  logon-content ">
        <div :class="{stateStyle:state>0}" class="t1"> <span>1</span>{{msg1}}</div>
        <div  :class="{stateStyle:state>1}" class="t2"> <span>2</span> {{msg2}} </div>
        <div class="t3"> <span>3</span> {{msg3}}</div>
        <div :class="{stateStyle:state>0}" class="t4">
            <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
             <img v-if='state==0' class="img1" src="@/assets/image/process_Arrow_icon_green@2x.png" alt="">
            <img v-if='state>0' class="img1" src="@/assets/image/process_Arrow_icon_gray@2x.png" alt="">
        </div>
        <div :class="{stateStyle:state>1}"  class="t4 t5"> 
            <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
            <img v-if='state== 1 || state==0' class="img1 img2" src="@/assets/image/process_Arrow_icon_green@2x.png" alt="">
            <img v-if='state>1' class="img1 img2" src="@/assets/image/process_Arrow_icon_gray@2x.png" alt="">
        </div>
      </div>
</template>
<script>
export default {
    props:[ 
        'msg1','msg2','msg3','state'
    ],
    data(){
        return{
        }
    },
    created(){
        // console.log(this.$store.getters.getStatus)
    }

}    
</script>
<style lang="less" scoped>
@import '../../assets/less/land';
.stateStyle{
    color: #38EEC1!important;    
    span{
         background: #38EEC1!important;    
         color: white!important;
         border: none!important;
    }
    i{
         background: #38EEC1!important; 
    }
   
}
</style>
