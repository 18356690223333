<template>
  <div>
    <div class="bgw">
      <landHeader class="bgw">
        <div class="bgw land_hader_top_min">
          <i class="fl"></i>
          <div class="fl logo htm1">找回密码</div>
        </div>
      </landHeader>
    </div>
    <div class="wrap mt120">
      <div v-if="landState!= 3" class="logon_content">
        <landChecking :state="landState" msg1="验证手机" msg2="重置密码" msg3="完成"></landChecking>
        <div v-show="landState ==1" class="txt1">
          <div>
            <label for="phone1">手机号码</label>
            <input v-model="phone" maxlength="11" type="txt" id="phone1" placeholder="请输入手机号码" />
          </div>
          <p>{{phoneTxt}}</p>
          <div>
            <label for="checkingPhone">验证码</label>
            <input
              v-model="phoneCode"
              type="txt"
              maxlength="6"
              id="checkingPhone"
              placeholder="请输入验证码"
              style="width:146px"
            />
            <button @click="phoneChecking()" v-if="time == 60 " class="fr send1">获取验证码</button>
            <button v-if="time != 60 " class="fr send1">{{time}}</button>
          </div>
          <p></p>
          <div class="send2">
            <button @click="tab1()">下一步</button>
          </div>
          <p>{{phoneErrMsg}}</p>
        </div>
        <div v-show="landState ==2" class="txt1">
          <div>
            <label for="password1">设置密码</label>
            <input v-model="password1" maxlength="12" type="txt" id="password1" placeholder="请输入密码" />
          </div>
          <p>{{passwordErr1}}</p>
          <div>
            <label for="password2">确认密码</label>
            <input
              v-model="password2"
              maxlength="12"
              type="txt"
              id="password2"
              placeholder="请再次输入密码"
            />
          </div>
          <p>{{passwordErr2}}</p>
          <!-- <div>
                <label for="vipNum">实体会员卡号</label>
                <input v-model="vipNum" maxlength="12" type="txt" id="vipNum" placeholder="请再次输入密码"  />
            </div>
          <p></p>-->
          <div class="send2">
            <button @click="tab2()">下一步</button>
          </div>
        </div>
      </div>
      <div v-if="landState == 3" class="logon_content">
        <div class="success">
          <div class="top1">修改成功</div>
          <div class="top2">
            密码修改成功，您以后可以使用新的密码登录孚诺大药房。
            <!-- 恭喜
            <span>{{phone}}</span>
            您已成为联众医药的线上会员-->
          </div>
          <div class="top2">
            <!-- 您的线上绑定会员号为：
            <span>{{vipNum}}</span>-->
          </div>
          <button @click="GOIndex()">返回首页</button>
        </div>
      </div>
    </div>
    <!-- <div class="bgw">
      <publicBottom></publicBottom>
    </div>-->
  </div>
</template>
<script>
import landHeader from "@/components/land/land_header";
import landChecking from "@/components/land/land_checking.vue";
// import publicBottom from "@/components/public/public_bottom";
import { submitForgetPass, login } from "@/request/land"; // 找回密码  登录
import { sendSms } from "@/request/public"; // 发送手机号
export default {
  components: {
    landHeader,
    landChecking
    // publicBottom
  },
  data() {
    return {
      checking1: 1, //验证
      landState: 1, // 1 2 3 的验证
      time: 60, //倒计时
      phone: "", //手机号
      phoneCode: "", //手机号验证码
      phoneTxt: "", //手机号提示错误
      phoneErrMsg: "", //手机号验证码报错
      password1: "",
      password2: "",
      passwordErr1: "",
      passwordErr2: "",
      vipNum: ""
    };
  },
  created() {},
  methods: {
    GOIndex() {
      this.$router.push("/?isRenderSocket=true");
    },
    phoneChecking() {
      if (this.phone == "") {
        this.phoneTxt = "请输入手机号";
        return false;
      } else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.phoneTxt = "手机号格式不正确";
        return false;
      } else if (this.time == 60) {
        this.phoneTxt = ''
        this.time = 59;
        this.times();
        sendSms({
          account: this.phone
        }).then(res => {
          if (res.code == 1) {
            this.checking1 = 2;
            this.$message({
              message: "发送成功",
              type: "success"
            });
          } else {
            this.$message({
              message: "发送失败",
              type: "warning"
            });
          }
        });
      }
    },
    times() {
      var Athis = this;
      var setTime = setTimeout(function() {
        if (Athis.time == 0) {
          clearTimeout(setTime);
          Athis.time = 60;
        } else {
          Athis.time--;
          Athis.times();
        }
      }, 1000);
    },
    tab2() {
      if (this.password1 == "") {
        this.passwordErr1 = "请输入密码";
        return false;
      } else if (this.password1.length < 6) {
        this.passwordErr1 = "请输入6-12位的密码";
        return false;
      } else if (this.password1 != this.password2) {
        this.passwordErr1 = "两次输入密码不一致";
        return false;
      } else {
        submitForgetPass({
          mobile: this.phone,
          password: this.password1,
          repassword: this.password2,
          code: this.phoneCode
        }).then(res => {
          if (res.code == 1) {
            this.landState = 3;
            this.land();
          }
        });
      }
    },
    land() {
      login({
        user_login: this.phone,
        user_pass: this.password1
      }).then(res => {
        if (res.code == 1) {
          // 登录
          let token = res.data.token;
          localStorage.setItem("lzyyToken", token);
          this.$user.token = token;
          this.$user.state = 1;
          // 保存号码
          let phone = this.phone;
          localStorage.setItem("lzyyPhone", phone);

          this.$message({
            message: "登录成功",
            type: "success"
          });
        }
      });
    },
    tab1() {
      if (!this.phone) {
        this.$message.error("请输入手机号");
        return;
      } else if (!this.phoneCode) {
        this.$message.error("请输入验证码");
        return;
      }
      this.landState = 2;
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/land";
</style>